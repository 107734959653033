import { Component, createRef } from "react";
import { FormControlLabel, Checkbox, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
// cmp
import SaveLoadCCCTrace from "./save-load-ccc-trace";
import TraceLogDownload from "./trace-log-download";
import TerminalEntry from "./terminal-entry";
import TerminalInput from "./terminal-input";
import TerminalJsonEditor from "./terminal-json-editor";
// services
import { withTranslation } from "react-i18next";
import { TerminalType, TerminalEntryType, SessionIdGeneral } from "../../services/terminal";
// types
import type { CSSProperties, UIEvent } from "react";
import type { JsonValue } from "type-fest";
import type { WithTranslation } from "react-i18next";
import type CC from "../../services/cc";
import type { RxCallback } from "../../types/roc-ws";
import type { CmdReTransmit } from "../../types/cc";
import type { UserId } from "../../types/user";
import type { GatewayId } from "../../types/gateway";
import type { TerminalType as TerminalTypeT, TerminalEntries, TerminalEntry as TerminalEntryT, Direction, SessionIdOrGeneral, Traces } from "../../types/terminal";

const DEFAULT_STYLE = {} as const satisfies CSSProperties;

const basicStyles = {
	text: grey[400],
	backgroundColor: grey[900],
	fontFamily: "monospace",
	fontSize: "1em",
} as const;

const defaultStyles = {
	list: {
		display: "flex",
		flexDirection: "column",
		margin: "8px 12px",
		whiteSpace: "pre-wrap",
		wordBreak: "break-all",
		cursor: "text",
	},
	listEntry: {
		padding: "2px 0",
	},
} as const;

type Props = Readonly<WithTranslation & {
	cc?: CC;
	module: TerminalTypeT;
	readOnly: boolean;
	gatewayId?: GatewayId;
	userId?: UserId;
	heightOffset?: number;
	promptSymbol?: string;
	style?: CSSProperties;
	listStyle?: CSSProperties;
	listEntryStyle?: CSSProperties;
	inputLineStyle?: CSSProperties;
	promptStyle?: CSSProperties;
	inputStyle?: CSSProperties;
	terminalEntries: TerminalEntries;
	sessionId?: SessionIdOrGeneral;
	traces?: Traces;
	onLoadFile: (file: File) => void;
	onSendCmd?: (cmd: string) => void;
	onClear?: () => void;
}>;

type State = {
	rTraceEnabled: boolean;
	jsonTraceEnabled: boolean;
	timestampEnabled: boolean;
	showJsonEditor: boolean;
	selectedJson: string | undefined;
	selectedJsonDir: Direction | undefined;
	scrollToBottom: boolean;
};

class Terminal extends Component<Props, State> {

	#ENTRIES_MAX = 10000;

	#refScrollElement = createRef<HTMLDivElement>();
	#refInputElement = createRef<HTMLInputElement>();

	public static defaultProps = {
		cc: undefined,
		gatewayId: undefined,
		userId: undefined,
		heightOffset: 156,
		promptSymbol: ">",
		style: {},
		listStyle: {},
		listEntryStyle: {},
		inputLineStyle: {},
		promptStyle: {},
		inputStyle: {},
		traces: [],
		sessionId: undefined,
		onSendCmd: () => {},
		onClear: () => {},
	} satisfies Partial<Props>;

	constructor(props: Props) {
		super(props);

		this.state = {
			rTraceEnabled: true,
			jsonTraceEnabled: true,
			timestampEnabled: true,
			showJsonEditor: false,
			selectedJson: undefined,
			selectedJsonDir: undefined,
			scrollToBottom: true,
		};

		this.handleScroll = this.handleScroll.bind(this);
		this.handleTerminalClick = this.handleTerminalClick.bind(this);
		this.handleAutoComplete = this.handleAutoComplete.bind(this);
		this.handleRTracePrintChange = this.handleRTracePrintChange.bind(this);
		this.handleJsonTracePrintChange = this.handleJsonTracePrintChange.bind(this);
		this.handleTimestampChange = this.handleTimestampChange.bind(this);
		this.toggleJsonEditorVisibility = this.toggleJsonEditorVisibility.bind(this);
		this.handleJsonTraceSelect = this.handleJsonTraceSelect.bind(this);
		this.handleJsonEditorSend = this.handleJsonEditorSend.bind(this);
		this.handleJsonEditorClosed = this.handleJsonEditorClosed.bind(this);
	}

	override componentDidUpdate(prevProps: Props) {
		if (this.props.sessionId && this.props.sessionId !== prevProps.sessionId && this.props.sessionId === SessionIdGeneral) {
			this.setState({
				showJsonEditor: false,
				selectedJson: undefined,
				selectedJsonDir: undefined,
			});
		}
		if (this.state.scrollToBottom && this.#refScrollElement.current) {
			this.#refScrollElement.current.scrollTop = this.#refScrollElement.current.scrollHeight - this.#refScrollElement.current.offsetHeight;
		}
	}

	handleRTracePrintChange(event, isInputChecked: boolean) {
		this.setState({
			rTraceEnabled: isInputChecked,
		});
	}

	handleJsonTracePrintChange(event, isInputChecked: boolean) {
		this.setState({
			jsonTraceEnabled: isInputChecked,
		});
	}

	handleTimestampChange(event, isInputChecked: boolean) {
		this.setState({
			timestampEnabled: isInputChecked,
		});
	}

	toggleJsonEditorVisibility() {
		this.setState((prevState) => ({
			showJsonEditor: !prevState.showJsonEditor,
		}));
	}

	handleJsonTraceSelect(terminalEntry: TerminalEntryT) {
		this.setState({
			showJsonEditor: true,
			selectedJson: JSON.stringify(terminalEntry.data, null, 2),
			selectedJsonDir: terminalEntry.dir,
		});
	}

	handleJsonEditorSend(data: JsonValue) {
		const cmd = {
			action: "reTransmit",
			dir: this.state.selectedJsonDir,
			data: data,
		} as const satisfies CmdReTransmit;
		this.props.cc.send(cmd);
	}

	handleJsonEditorClosed() {
		this.setState({
			showJsonEditor: false,
			selectedJson: undefined,
			selectedJsonDir: undefined,
		});
	}

	handleScroll(event: UIEvent<HTMLDivElement>) {
		if (Math.ceil(event.target.scrollTop) < event.target.scrollHeight - event.target.offsetHeight) {
			this.setState({
				scrollToBottom: false,
			});
		} else {
			this.setState({
				scrollToBottom: true,
			});
		}
	}

	handleTerminalClick() {
		this.#refInputElement.current?.focus();
	}

	handleAutoComplete(cmd: string, callback: RxCallback) {
		const data = {
			action: "getAutoComplete",
			module: this.props.module,
			command: cmd,
		};
		this.props.cc.send(data, callback);
	}

	getFilteredTerminalEntries() {
		const terminalEntries = this.props.terminalEntries.slice(-this.#ENTRIES_MAX);
		if (this.state.rTraceEnabled && this.state.jsonTraceEnabled) {
			return terminalEntries;
		}
		return terminalEntries.filter((terminalEntry) => (
			(terminalEntry.type === TerminalEntryType.RTRACE && this.state.rTraceEnabled) || (terminalEntry.type === TerminalEntryType.JSON_TRACE && this.state.jsonTraceEnabled)
		));
	}

	override render() {
		const {
			t, module, readOnly, gatewayId, userId, heightOffset = 156, promptSymbol = ">",
			style = DEFAULT_STYLE, listStyle = DEFAULT_STYLE, listEntryStyle = DEFAULT_STYLE, inputLineStyle = DEFAULT_STYLE, promptStyle = DEFAULT_STYLE, inputStyle = DEFAULT_STYLE,
			terminalEntries, sessionId, traces,
			onLoadFile, onSendCmd, onClear,
		} = this.props;

		const terminalStyle = {
			height: `calc(100vh - ${heightOffset + 42}px)`,
			overflow: "hidden scroll",
			color: basicStyles.text,
			backgroundColor: basicStyles.backgroundColor,
			fontFamily: basicStyles.fontFamily,
			fontSize: basicStyles.fontSize,
		} as const;

		return (
			<>
				{(module === TerminalType.CCC) &&
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "0 12px" }}>
						<div>
							<FormControlLabel
								label={t("gateway.rtrace")}
								control={<Checkbox checked={this.state.rTraceEnabled} onChange={this.handleRTracePrintChange} />}
								style={{ whiteSpace: "nowrap" }}
							/>
							<FormControlLabel
								label={t("gateway.jsonTrace")}
								control={<Checkbox checked={this.state.jsonTraceEnabled} onChange={this.handleJsonTracePrintChange} />}
								style={{ whiteSpace: "nowrap" }}
							/>
							<FormControlLabel
								label={t("gateway.timestamp")}
								control={<Checkbox checked={this.state.timestampEnabled} onChange={this.handleTimestampChange} />}
								style={{ whiteSpace: "nowrap" }}
							/>
						</div>
						<div style={{ display: "flex" }}>
							{(sessionId !== SessionIdGeneral) &&
								<Button
									variant="contained"
									size="small"
									onClick={this.toggleJsonEditorVisibility}
									style={{ marginRight: "50px" }}
								>
									{t("gateway.jsonEditor")}
								</Button>
							}
							<SaveLoadCCCTrace
								traces={traces}
								sessionId={sessionId}
								gatewayId={gatewayId}
								userId={userId}
								terminalEntries={this.getFilteredTerminalEntries()}
								onLoadFile={onLoadFile}
							/>
							{sessionId &&
								<TraceLogDownload
									traces={traces}
									sessionId={sessionId}
									logs={terminalEntries}
								/>
							}
						</div>
					</div>
				}
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div
						ref={this.#refScrollElement}
						style={{ ...terminalStyle, ...style, width: this.state.showJsonEditor ? "70%" : "100%" }}
						onScroll={this.handleScroll}
						onClick={this.handleTerminalClick}
					>
						<pre style={{ ...defaultStyles.list, ...listStyle }}>
							<div onClick={(event) => (event.stopPropagation())}>
								{
									this.getFilteredTerminalEntries().map((terminalEntry, index) => (
										<div key={index} style={{ ...defaultStyles.listEntry, ...listEntryStyle }}>
											<TerminalEntry
												entry={terminalEntry}
												promptSymbol={promptSymbol}
												timestampEnabled={this.state.timestampEnabled}
												onJsonTraceClick={this.handleJsonTraceSelect}
											/>
										</div>
									))
								}
							</div>
							{(sessionId === undefined) &&
								<TerminalInput
									refInput={this.#refInputElement}
									readOnly={readOnly}
									promptSymbol={promptSymbol}
									inputLineStyle={inputLineStyle}
									promptStyle={promptStyle}
									inputStyle={inputStyle}
									basicStyles={basicStyles}
									onAutoComplete={this.handleAutoComplete}
									onSendCmd={onSendCmd!}
									onClear={onClear!}
								/>
							}
						</pre>
					</div>
					{this.state.showJsonEditor &&
						<TerminalJsonEditor
							jsonString={this.state.selectedJson ?? ""}
							onSend={this.handleJsonEditorSend}
							onClose={this.handleJsonEditorClosed}
						/>
					}
				</div>
			</>
		);
	}

}

export default withTranslation()(Terminal);

import type { ReactNode, CSSProperties } from "react";

type Props = Readonly<{
	title: string;
	subTitle?: ReactNode;
	titleWidth?: number;
	styleLeft?: CSSProperties;
	styleRight?: CSSProperties;
	children: ReactNode;
}>;

const DEFAULT_STYLE = {} as const satisfies CSSProperties;

const RowEntry = ({ title, subTitle = undefined, titleWidth = 30, styleLeft = DEFAULT_STYLE, styleRight = DEFAULT_STYLE, children }: Props) => (
	<div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px"}}>
		<div style={{width: `calc(${titleWidth}% - 24px)`, minWidth: "80px", paddingRight: "24px", fontWeight: 500, ...styleLeft}}>
			{(subTitle === null) ? title :
				<>
					<div style={{ fontWeight: 500 }}>{title}</div>
					<div className="row-entry-subtitle">{subTitle}</div>
				</>
			}
		</div>
		<div style={{width: `${100 - titleWidth}%`, ...styleRight}}>
			{children}
		</div>
	</div>
);

export default RowEntry;

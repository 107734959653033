import { useState } from "react";
import Svg from "../svg";
// types
import type { CSSProperties, RefObject, KeyboardEvent } from "react";
import type { RxCallback } from "../../types/roc-ws";

type Props = Readonly<{
	refInput: RefObject<HTMLInputElement>;
	readOnly: boolean;
	promptSymbol: string;
	inputLineStyle: CSSProperties;
	promptStyle: CSSProperties;
	inputStyle: CSSProperties;
	basicStyles: { // TODO
		text: string;
		backgroundColor: string;
		fontFamily: string;
		fontSize: string;
	};
	onAutoComplete: (cmd: string, callback: RxCallback) => void;
	onSendCmd: (cmd: string) => void;
	onClear: () => void;
}>;

const TerminalInput = ({ refInput, readOnly, promptSymbol, inputLineStyle, promptStyle, inputStyle, basicStyles, onAutoComplete, onSendCmd, onClear }: Props) => {
	const [cmd, setCmd] = useState("");
	const [cmdHistory, setCmdHistory] = useState<Array<string>>([]);
	const [cmdHistorySelection, setCmdHistorySelection] = useState(0);

	const defaultStyles = { // TODO
		inputLine: {
			display: "flex",
			flexDirection: "row",
			alignItems: "baseline",
			paddingTop: "2px",
		},
		prompt: {
			paddingRight: "8px",
		},
		input: {
			padding: 0,
			width: "100%",
			border: "none",
			outline: "none",
			color: basicStyles.text,
			backgroundColor: basicStyles.backgroundColor,
			fontFamily: basicStyles.fontFamily,
			fontSize: basicStyles.fontSize,
		},
		clearButtonContainer: {
			position: "relative",
			top: "3px",
			marginTop: "-3px",
			cursor: "pointer",
		},
	} as const;

	const handleTab = (subCmd: string) => {
		onAutoComplete(subCmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok" && msg.payload.info === "getAutoComplete") {
				if (refInput.current) {
					const cursorPosition = refInput.current.selectionStart;
					if (typeof cursorPosition === "number") {
						setCmd((prevCmd) => (`${prevCmd.substring(0, cursorPosition)}${msg.payload.data}${prevCmd.substring(cursorPosition)}`));
						// TODO: improve code
						refInput.current.selectionStart = cursorPosition + msg.payload.data.length; // eslint-disable-line react-compiler/react-compiler
						refInput.current.selectionEnd = cursorPosition + msg.payload.data.length;
					}
				}
			}
		});
	};

	const handleEnter = () => {
		onSendCmd(cmd);

		setCmdHistorySelection(cmdHistory.length + 1);
		setCmdHistory((prevCmdHistory) => ([...prevCmdHistory, cmd]));
		setCmd("");
	};

	const prevCommand = () => {
		if (cmdHistorySelection > 0) {
			setCmd(cmdHistory[cmdHistorySelection - 1]);
			setCmdHistorySelection((prevCmdHistorySelection) => (prevCmdHistorySelection - 1));
		}
	};

	const nextCommand = () => {
		if (cmdHistorySelection < cmdHistory.length - 1) {
			setCmd(cmdHistory[cmdHistorySelection + 1]);
			setCmdHistorySelection((prevCmdHistorySelection) => (prevCmdHistorySelection + 1));
		} else {
			setCmd("");
			setCmdHistorySelection(cmdHistory.length);
		}
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		switch (event.key) {
			case "Tab":
				event.preventDefault();
				handleTab(cmd.substring(0, event.target.selectionStart));
				break;
			case "Enter":
				event.preventDefault();
				handleEnter();
				break;
			case "ArrowUp":
				event.preventDefault();
				prevCommand();
				break;
			case "ArrowDown":
				event.preventDefault();
				nextCommand();
				break;
			default:
			// console.log(event, event.key);
		}
	};

	return (
		<div style={{ ...defaultStyles.inputLine, ...inputLineStyle }}>
			<span style={{ ...defaultStyles.prompt, ...promptStyle }}>{promptSymbol}</span>
			<input
				ref={refInput}
				type="text"
				autoFocus={true}
				value={cmd}
				readOnly={readOnly}
				style={{ ...defaultStyles.input, ...inputStyle }}
				onChange={(event) => (setCmd(event.target.value))}
				onKeyDown={handleKeyDown}
			/>
			<div style={defaultStyles.clearButtonContainer} onClick={onClear}>
				<Svg src="actions/clear.svg" color={basicStyles.text} size={15} />
			</div>
		</div>
	);
};

export default TerminalInput;

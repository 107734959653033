import { useNavigate, useParams } from "react-router";
// types
import type { ComponentClass } from "react";
import type { ReactRouterProps } from "../types/misc";

const withNavigateAndParams = <P extends ReactRouterProps>(Component: ComponentClass<P>) => {
	const WrappedComponent = (props: Omit<P, keyof ReactRouterProps>) => {
		const navigate = useNavigate();
		const params = useParams();

		return <Component {...props} navigate={navigate} location={location} params={params} />;
	};

	WrappedComponent.displayName = `withNavigateAndParams(${Component.displayName ?? Component.name})`;
	WrappedComponent.WrappedComponent = Component;

	return WrappedComponent;
};

export default withNavigateAndParams;

import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
// services
import { downloadData } from "../../services/terminal";
import { icons } from "@local/theme";
// types
import type { TerminalEntries, SessionId, Traces } from "../../types/terminal";

type Props = Readonly<{
	traces: Traces;
	sessionId: SessionId;
	logs: TerminalEntries;
}>;

const TraceLogDownload = ({ traces, sessionId, logs }: Props) => {
	const { t } = useTranslation();

	const handleExportAllClick = () => {
		const data = traces.map((trace) => (
			`Session Id: ${trace.sessionId}\r\n${trace.logs.map((log) => ((typeof log.data === "string") ? log.data : JSON.stringify(log.data))).join("\r\n")}`
		)).join("\r\n\r\n");
		downloadData("session-traces.txt", "text/plain", data);
	};

	const handleExportClick = () => {
		const data = logs.map((log) => ((typeof log.data === "string") ? log.data : JSON.stringify(log.data))).join("\r\n");
		downloadData(`${sessionId}.txt`, "text/plain", data);
	};

	return (
		<div>
			<Button
				variant="contained"
				size="small"
				startIcon={<icons.Download />}
				onClick={handleExportClick}
				style={{marginLeft: "12px"}}
			>
				{t("gateway.export")}
			</Button>
			<Button
				variant="contained"
				size="small"
				startIcon={<icons.Download />}
				onClick={handleExportAllClick}
				style={{marginLeft: "12px"}}
			>
				{t("gateway.exportAll")}
			</Button>
		</div>
	);
};

export default TraceLogDownload;
